import React from 'react'
import CeoImage from "../../assets/images/nitin.png"
const aboutUs = () => {
  return (
    <section className="about-hero">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="about-hero-description">
                        <h6 className="text-uppercase text-center">Our Mission</h6>
                        <h1 className="text-center">We Believe To Provide Best Homecare Medical Service At your DoorSteps.
                        </h1>
                    </div>
                </div>
            </div>
           
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="aboutus-caption">
                        <p className="text-left">HealBots is a pioneer in delivering world class healthcare directly to our
                            patients' homes and aims to make primary healthcare more accessible, affordable, and
                            responsive
                            to patients' needs than ever before. We have a team of highly trained professionals who
                            provide
                            quality care. As a healthcare partner, we fulfill the qualities you look for, such as
                            responsiveness and credibility.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="aboutus-mission">
                        <h3>Mission</h3>
                        <p className="text-left">As a healthcare partner, we fulfill the qualities you look for, such as
                            responsiveness and credibility.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="aboutus-vision">
                        <h3>Vision</h3>
                        <p className="text-left">
                            Our unique endowment and lineage of healthcare, insurance, hospitality and real estate drove
                            Our mission is to become a platform with a host of lifecare, lifestyle and hospitality
                            offerings, tailored to improve and enrich the quality of life for seniors.
                        </p>
                        <p className="text-left">
                            Our vision is to be the most loved and trusted brand for seniors and their families, by
                            helping these seniors improve and enrich their quality of life. </p>
                        <p className="text-left">
                            This vision inspired us to implement a perfect blend of life care and lifestyle offerings
                            that deliver a comprehensive and seamless experience that anticipates and responds to their
                            evolving needs and desires. </p>
                    </div>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="aboutus-whyhealbots">
                        <h3>Why HealBots</h3>
                        <p className="text-left">
                            The dynamic social fabric of our society, the changing times and the growing pressures of
                            modern lifestyle have compelled us to evolve. In recent years, families have opt for
                            trustworthy external partners to assist their loved ones because of the hassles of daily
                            medical assistance, hospital atmosphere, and lack of personalized care.

                        </p>
                        <p className="text-left">
                            HealBots was conceptualized and fueled with the vision to be the most admired brand for
                            seniors and their families. We want our elders to look at life as they get older as a new
                            beginning. With HealBots, you choose trusted healthcare support for your loved one’s. </p>
                        <p className="text-left">
                            Our unique endowment and lineage of healthcare, insurance, hospitality and real estate drove
                            our vision – to become a platform with a host of lifecare, lifestyle and hospitality
                            offerings, tailored to improve and enrich the quality of life for seniors.
                        </p>
                        <p>Our unique endowment and lineage of healthcare, insurance, hospitality and real estate drove
                            our vision – to become a platform with a host of lifecare, lifestyle and hospitality
                            offerings, tailored to improve and enrich the quality of life for seniors.Our unique
                            endowment and lineage of healthcare, insurance, hospitality and real estate drove our vision
                            – to become a platform with a host of lifecare, lifestyle and hospitality offerings,
                            tailored to improve and enrich the quality of life for seniors.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="aboutus-whyhealbots">
                        <h3>Our Team</h3>
                        <img className="team-nitin-img" src={CeoImage} />
                        <h6 className="team-nitin-desgn">Nitin Ranjan Kumar (CEO)</h6>
                        <p className="text-left">
                        He has over 10 year's diversified experience across  healthcare industry in the areas of business development,sales and marketing.He has worked with various opthalmic company.His last assignment was with one of the largest homecare company where he worked for about 6 years.He has been recognised in developing new area for homecare,done many doctors and hospital sign up.Nitin has done his graduation from Delhi University and MBA from ICFAI, Gurgaon.
                        </p>
                    </div>
                </div>
            </div>


        </div>

    </section>
  )
}

export default aboutUs