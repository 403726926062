import React from "react";
import CriticalCare from "../../../assets/images/service/critical-care.jpg"

const serviceCriticalCare = () => {
  return (
    <section className="service-detail-wrapper">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-12 col-md-12">
            <div className="service-detail-hero-head">
              <h1>Critical Care</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-7 col-lg-7 col-md-7">
            <div className="service-details-content">
              <h4>What Is The HealBots Trained Attendant Service?</h4>
              <p>
                As a leading home healthcare services provider, HealBots offers
                a wide range of activities and support for in-home patients. The
                elder, ill or otherwise patients at home need help with
                grooming, mobilization, and feeding, and they also need their
                vital signs like weight, heart rate, blood pressure, etc
                regularly monitored. That’s where the trained attendant from
                HealBots comes into play.
              </p>
            </div>
            <div className="service-details-content">
              <h4>When Does A Trained Attendant Need For?</h4>
              <p>
                Even if only one person in the family gets ill, the entire
                family suffer. The need for help to perform even daily
                activities can be the worst feeling. In such cases, the need for
                a trained attendant arises. Be it Taking Care of Your Elderly
                Loved ones, the Care of a Mother and her Baby, or Caring for a
                Patient with Post-Operational situations. Understanding this, we
                provide world-class, trustworthy and round-the-clock home
                healthcare services, so you can completely relax and let go of
                all fears.
              </p>
            </div>
            <div className="service-details-content">
              <h4>How Can We Help?</h4>
              <p>
                Care is provided in the comfort of a person’s home by our home
                health care attendants. Patients are helped with their daily
                activities by HealBots trained attendants who assist them in
                bathing, dressing, eating, and maintaining their personal
                hygiene, along with monitoring of vitals etc. We offer both 12
                hours for the day or the night and 24 hours i.e full day of
                services by our trained attendants.
              </p>
            </div>
            <div className="service-details-content">
              <h4>Why Should You Choose Us?</h4>
              <p>
                With HealBots, you will be choosing the top-tier services for
                your loved ones in times of their needs.
              </p>
              <ul>
                <li>
                  HealBots attendants are supervised by senior nurses and
                  doctors.
                </li>
                <li>
                  HealBots attendants are trained by the best and get their
                  backgrounds checked thoroughly.
                </li>
                <li>
                  In case of any emergency, you will always be able to reach a
                  nurse or doctor.
                </li>
                <li>HealBots services are easily accessible and affordable.</li>
              </ul>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5">
            <figure>
              <img
                src={CriticalCare}
                alt="Critical Care"
              />
            </figure>
          </div>
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="faq-details-content">
                <h2>Frequently Asked Questions</h2>
              </div>
              <div className="faq-details-wrapper">
                <div className="faq-details-content">
                  <h4>
                    What are the responsibilities of HealBots’ attendants?
                  </h4>
                  <p>
                    HealBots’ trained attendants will assist the patients in
                    bathing, dressing, eating, and maintaining their personal
                    hygiene, along with monitoring of vitals etc.
                  </p>
                </div>
                <div className="faq-details-content">
                  <h4>
                    Are the trained attendants at HealBots medically qualified?
                  </h4>
                  <p>
                    No, our attendants are not medical professionals, but they
                    do receive basic training in day-to-day needs as well as
                    basic medical assistance.
                  </p>
                </div>
                <div className="faq-details-content">
                  <h4>How long will a trained attendant stay at my home?</h4>
                  <p>
                    You can choose between the three available timings, i.e 12
                    hours for the day, 12 hours for the night or 24 hours a full
                    day. HealBots’ Trained Attendants are available as per your
                    requirements.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default serviceCriticalCare;
