import React from "react";
import Nurse from "../../../assets/images/service/nurse.JPG"
const serviceNurse = () => {
  return (
    <section className="service-detail-wrapper">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-12 col-md-12">
            <div className="service-detail-hero-head">
              <h1>Nurse Attendant</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-7 col-lg-7 col-md-7">
            <div className="service-details-content">
              <p>
                The home nursing services offered by HealBots include a wide
                range of healthcare offered in patients’ homes. Our services are
                available for patients suffering from all kinds of illnesses. We
                provide you with affordable home nursing services, which makes
                HealBots the best choice. You can receive a wide range of
                healthcare services through a home nursing service. These
                services are not just convenient and effective but are also
                affordable compared to hospitals.{" "}
              </p>
              <em>Our home-care services also consist of: </em>
              <ul>
                <li>Elder Care</li>
                <li>Physiotherapy</li>
                <li>Mother and Baby Care</li>
                <li>Trained attendant</li>
                <li>Medical Equipment</li>
              </ul>
            </div>
            <div className="service-details-content">
              <h4>Nursing Type & Timing</h4>
            </div>
            <div className="service-details-content">
              <h4>How Can We Help?</h4>
              <p>
                Care is provided in the comfort of a person’s home by our home
                health care attendants. Patients are helped with their daily
                activities by HealBots trained attendants who assist them in
                bathing, dressing, eating, and maintaining their personal
                hygiene, along with monitoring of vitals etc. We offer both 12
                hours for the day or the night and 24 hours i.e full day of
                services by our trained attendants.
              </p>
            </div>
            <div className="service-details-content">
              <h4>Short-Term Nursing Care</h4>
              <p>
                Typically, short-term nurses provide healthcare services for 1-4
                hours a day or a specific amount of time. Such services are for
                assisting with the recovery process after surgery, an injury or
                another medical condition that is expected to improve.
              </p>
            </div>
            <div className="service-details-content">
              <h4>Long-Term Nursing Care</h4>
              <p>
                Nurses who provide long-term healthcare services for 12-24 hours
                a day. In such services, we provide comprehensive medical,
                surgical, and physical care to chronically ill or disabled
                individuals.{" "}
              </p>
            </div>
            <div className="service-details-content">
              <h4>Nursing Attendant at Home</h4>
              <p>
                Nursing attendants or trained attendants of HealBots take care
                of patients’ needs in daily activities. Apart from providing
                supportive care, they are not allowed to perform any medical
                procedures.
              </p>
            </div>
            <div className="service-details-content">
              <h4>Benefits of Home Nursing Care</h4>
              <p>
                One of the benefits of receiving nursing care at home is that it
                is way more convenient than getting admitted in the a hospital,
                as it is proven that people tend to recover faster from illness
                when they have their loved ones around them. The other benefits
                of home nursing care are:
              </p>
              <ul>
                <li>
                  The diet is an important part of a patient’s routine, and the
                  HealBots’ in-home nurse supports their diet and nutrition.
                </li>
                <li>
                  HealBots’ in-home nursing care also helps to prevent
                  unnecessary hospitalization of patients with chronic health
                  conditions.
                </li>
                <li>
                  It also offers individual attention and support, along with
                  medical management.
                </li>
              </ul>
            </div>
            <div className="service-details-content">
              <h4>Expect the Best from HealBots</h4>
              <p>
                Our first step would be to take an appointment with you and
                visit you at home to discuss your requirements for your loved
                ones as well as health related questions.
              </p>
              <p>
                The nurse will also discuss the patient’s condition and
                requirements, as they will provide updates about the patient’s
                progress to the doctor.
              </p>
              <p>
                We make sure that our home nursing attendant monitors all that a
                patient eats and drinks, along with their vitals, i.e blood
                pressure, temperature, heart rate, and breathing. They will also
                ensure that the patient is taking the right medications.
              </p>
              <p>
                Under their supervision, they continue to inquire about any
                difficulties the patient might be experiencing while repeatedly
                checking the patient’s safety.
              </p>
            </div>
            <div className="service-details-content">
              <h4>What Conditions We Treat</h4>
              <ul>
                <li>Cardiac & Respiratory Care</li>
                <li>Post operative Care</li>
                <li>Nursing Ortho Care</li>
                <li>Chronic Care</li>
                <li>Diabetic Care</li>
                <li>Elder Care</li>
                <li>Mother and Baby Care</li>
              </ul>
            </div>
            <div className="service-details-content">
              <h4>Why choose Nursing Care from HealBots?</h4>
              <ul>
                <li>
                  Nurses at HealBots are well-educated and highly qualified.
                </li>
                <li>
                  They possess a high level of skill with years of experience
                  caring for several patients.
                </li>
                <li>
                  We ensure that our nurses are in constant contact with the
                  doctors to know what, how and why certain machines should be
                  administered.
                </li>
                <li>
                  Nurses at HealBots are not only skilled, but are also fully up
                  to date with medical equipment and tools used in nursing.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5">
            <figure>
              <img
                src={Nurse}
                alt="Medical Nurse"
              />
            </figure>
          </div>
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="faq-details-content">
                <h2>Frequently Asked Questions</h2>
              </div>
              <div className="faq-details-wrapper">
                <div className="faq-details-content">
                  <h4>What are the things that HealBots nurse would do?</h4>
                  <p>
                    You can receive post-operative care, tracheostomy care,
                    urinary catheterization, suture removal, wound care,
                    injection, oxigen, administration, IV infusion, and more
                    from HealBots’ trained nurses.{" "}
                  </p>
                </div>
                <div className="faq-details-content">
                  <h4>How often a nurse will visit the patient?</h4>
                  <p>
                    Patient’s needs and treatment plans determine the frequency
                    of nursing visits.
                  </p>
                </div>
                <div className="faq-details-content">
                  <h4>
                    Can I avail 12-hr or 24-hr nursing service from HealBots?
                  </h4>
                  <p>
                    Yes, you can avail 12 hours or 24 hours in-home nursing
                    services from HealBots.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default serviceNurse;
