import React from 'react'

const contactUs = () => {
  return (
    <section className="contact-hero">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="contact-hero-description">
                        <h1 className="text-center">Our teams are here to help</h1>
                        <h6 className="text-captalize text-center">Get in touch and let us know how we can help.</h6>
                    </div>
                </div>
            </div>
           
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="contactSupportCardWrapper">
                    <div className="card callSupportCard">
  <div className="card-body">
    <span className="icon">
    <i className="fa fa-mobile" aria-hidden="true"></i>
    </span>
    <div className="carddetail">
        <h3>Call</h3>
        <p>We’d love to talk about how we can help you.</p>
    </div>
    <a href="tel:9582566200" className="btn SupportBtn" type="button">call me</a>
  </div>
</div>
                    <div className="card mailSupportCard">
  <div className="card-body">
    <span className="icon">
    <i className="fa fa-envelope-o" aria-hidden="true"></i>
    </span>
    <div className="carddetail">
        <h3>Mail</h3>
        <p>We’re waiting to receive your mail.</p>
    </div>
    <a href="mailto:nitin.ranjan@healbots.in" className="btn SupportBtn" type="button">Support</a>
  </div>
</div>
                    <div className="card docSupportCard">
  <div className="card-body">
    <span className="icon">
    <i className="fa fa-file-text-o" aria-hidden="true"></i>
    </span>
    <div className="carddetail">
        <h3>Brochure</h3>
        <p>Download our Brochure and get our support ecosystem.</p>
    </div>
    <button className="btn SupportBtn" type="button">Download</button>
  </div>
</div>
                    </div>
                </div>
            </div>
        <div className="row align-items-center">
                <div className="col-xl-8 col-lg-12 col-sm-12 col-12 m-auto">
                    <div className="contactForm">
                    <h2 className="text-center">Enquire Now</h2>
                        <h6 className="text-captalize text-center">We’ll help you find the solution for you.</h6>

                    <form>
  <div className="row">
    <div className="col-sm-6 col-12">
      <input type="text" className="form-control form-control-lg" placeholder="Full Name" name="name" />
    </div>
    <div className="col-sm-6 col-12">
      <input type="email" className="form-control form-control-lg" placeholder="Email Id" name="email" />
    </div>
    <div className="col-sm-6 col-12">
      <input type="number" className="form-control form-control-lg" placeholder="Phone Number" name="phone" />
    </div>
    <div className="col-sm-6 col-12">
      <input type="text" className="form-control form-control-lg" placeholder="Address" name="address" />
    </div>
    <div className="col-12">
    <textarea className="form-control form-control-lg" rows="3" id="comment" name="text"></textarea>
    </div>
    <div className="col-12">
    <button className="btn contactSubmitBtn" type="button">Submit Now</button>
    </div>
  </div>
</form>
                    </div>
</div>
</div>
        </div>
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.068190299661!2d77.2388827145581!3d28.567714993763584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce372ee75d4b7%3A0x5a68ebff0d27bfc6!2sHealBots!5e0!3m2!1sen!2sin!4v1653734691256!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> */}
    </section>
  )
}

export default contactUs