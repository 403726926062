import React from "react";
import ServiceTheme1 from "../../assets/images/service-theme-1.png";
import ServiceTheme2 from "../../assets/images/service-theme-2.png";
import ServiceTheme3 from "../../assets/images/service-theme-3.png";
import ServicesItem from "./services-item";

const services = () => {
  return (
    <section className="home-service-hero">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-12 col-md-12">
            <div className="home-service-hero-head">
              <h4>Services at Doorstep</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
          </div>
        </div>
      <ServicesItem/>
      </div>
    </section>
  );
};

export default services;
