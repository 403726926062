import React from "react";

const serviceDietitianConsultant = () => {
  return (
    <section className="home-service-hero">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-12 col-md-12">
            <div className="home-service-hero-head">
              <h4>Dietitian Consultant</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default serviceDietitianConsultant;
