import React from 'react'

const faq = () => {
  return (
    <section className="terms-hero">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="terms-hero-description">
                        <h1 className="text-center">FAQs</h1>
                    </div>
                </div>
            </div>
           
            
          
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="terms-detail">
                        <h3>What are the duties of HealBots’ Attendant?</h3>
                        <p className="text-left">
                        HealBots attendants will assist you with a number of tasks, such as administering oral medication, monitoring vital signs of patients, stimulating ambulation, and grooming the patient.
                        </p>                       
                        <h3> Do the trained attendants wash dishes, clothes, and utensils for the family?</h3>
                        <p className="text-left">
                        No, our attendants will only assist the patient's daily activities.
                        </p>                       
                    </div>
                </div>
            </div>
        </div>

    </section>
  )
}

export default faq