import React from 'react'
import LogoIcon from "../../assets/images/logo-icon.png";
import Logo from "../../assets/images/logo.png";
import BookNowForm from '../Components/bookNowForm';
import { Link } from "react-router-dom";


const header = () => {

    
  return (
    <React.Fragment>
        <div className="container-fluid header-top-area">
    <div className="row align-items-center">
        <div className="col-xl-8 col-lg-6 col-md-6 col-7">
            <div className="header-top-wrapper">
                <div className="HTW-Message">
                    <img className="logo_dark" src={LogoIcon} alt="HealBots" />
                    <p>Welcome to HealBots. We provides best home healthcare services in <strong>Delhi</strong>.</p>
                </div>
                <div className="HTW-Phone">
                    <a href="tel:9582566200"><i className="fa-solid fa-mobile-screen-button"></i>+91 95825 66200</a>
                </div>
            </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6 col-5">
            <ul className="header-top-wrapper-right">
                <li className="d-none d-sm-block">
                <a href="mailto:nitin.ranjan@healbots.in" className="HTWR-Mail"><i className="fa-solid fa-envelope"></i><span>nitin.ranjan@healbots.in</span></a>
                </li>
                <li>
                    <a href="https://goo.gl/maps/UMTCM3Zr8VpxtQgT7" className="HTWR-Location"><i className="fa-solid fa-location"></i><span>Use Location</span></a>
                </li>
            </ul>
        </div>
    </div>
</div>
<nav className="navbar navbar-expand-sm bg-white navbar-dark sticky-top">
    <div className="container-fluid">
        <a className="navbar-brand" href="/">
            <img className="logo_dark" src={Logo} alt="HealBots" />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav me-auto">
                <li className="nav-item">
                    <Link className="nav-link" to="/">Home</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/services">Services</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/about-us">About US</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/contact-us">Contact US</Link>
                </li>
            </ul>
            <div className="d-flex">
        <button className="btn bookNowBtn" type="button"  data-bs-toggle="modal" data-bs-target="#myModal">Book Now</button>
      </div>
        </div>
    </div>
</nav>
<BookNowForm IdProp="myModal"/>

    </React.Fragment>
  )
}

export default header