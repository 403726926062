import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Layout from "./Web/Layout";
import Route from './Route'
import { BrowserRouter } from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Layout>
      <Route />
    </Layout>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
