import React from "react";
import Physiotherapy from "../../../assets/images/service/physio.jpg"
const servicePhysiotherapy = () => {
  return (
    <section className="service-detail-wrapper">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-12 col-md-12">
            <div className="service-detail-hero-head">
              <h1>Physiotherapy</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-7 col-lg-7 col-md-7">
            <div className="service-details-content">
              <p>
                Physiotherapy is one of the allied health professions. Physical
                therapists provide the service through physical examination,
                diagnosis, management, prognosis, patient education, physical
                intervention, rehabilitation, disease prevention, and health
                promotion.{" "}
              </p>
              <p>
                Physiotherapy helps people with illnesses or injuries that limit
                their ability to move and function normally. A physiotherapist
                uses the individual’s medical history and physical examination
                to diagnose the condition, develop a treatment plan, and, when
                necessary, integrate lab or imaging studies such as X-Rays, CT
                Scans, or MRIs.
              </p>
              <p>
                It is common for Physiotherapy treatment to include manual
                therapy, and manipulation, as well as prescription of or
                assistance with specific exercises. Additionally, it includes
                mechanical devices, such as traction, electrophysical modalities
                which include heat, cold, electricity, sound waves, radiation,
                assistive devices, prostheses, orthoses, and other
                interventions.
              </p>
            </div>
            <div className="service-details-content">
              <h4>Benefits of In-Home Physiotherapy Sessions</h4>
              <p>
                It is well-known that physiotherapy is effective in restoring
                health and improving physical strength, function, and mobility
                in patients. Physiotherapists provided by HealBots will perform
                physiotherapy at your home in order to enhance your
                physiotherapy experience. Here are some benefits you will get
                with in-home physiotherapy sessions:
              </p>
              <ul>
                <li>Convenience</li>
                <li>Personalized care</li>
                <li>A faster healing process</li>
                <li>No mobility issues</li>
                <li>Better time management</li>
                <li>Cost-effectiveness</li>
                <li>Family support and supervision</li>
              </ul>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5">
            <figure>
              <img
                src={Physiotherapy}
                alt="Medical Attendant"
              />
            </figure>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="service-details-content">
              <h4>When do you need the Physiotherapist?</h4>
              <p>
                The inevitable costs of a modern lifestyle are knee pain, back
                pain, and neck strain. It is highly recommended for a
                Physiotherapy session if you experience persistent pain after
                doing the specific movement, and it did not subdue over time. To
                make your treatment more convenient, you can opt for our in-home
                care services.
              </p>
            </div>
            <div className="service-details-content">
              <p>
                <em>
                  Medical issues that can be addressed through physiotherapy
                  include the following:
                </em>
              </p>
            </div>
            <div className="service-details-content">
              <h4>Paralysis</h4>
              <p>
                When physiotherapy is done regularly, muscle tone can be
                improved, allowing paralyzed patients to move around as much as
                possible.
              </p>
            </div>
            <div className="service-details-content">
              <h4>Back Pain</h4>
              <p>
                There are many factors that can cause persistent back pain.
                Sedentary lifestyle, poor posture, and daily travel all
                contribute to it.{" "}
              </p>
            </div>
            <div className="service-details-content">
              <h4>Respiratory</h4>
              <p>
                Physiotherapy isi beneficial in treating respiratory problems
                such as Asthma, Chronic Obstructive Pulmonary Diseases, and
                Cystic Fibrosis.
              </p>
            </div>
            <div className="service-details-content">
              <h4>Post-Surgical Rehab</h4>
              <p>
                We offer in-home physiotherapy services if you have recently
                undergone a surgery. HealBots’ in-home physiotherapy helps you
                recover faster.
              </p>
            </div>
            <div className="service-details-content">
              <h4>Perkinson Disease</h4>
              <p>
                Patients with perkinson disease can improve their mobility and
                independence through physiotherapy treatments.
              </p>
            </div>
            <div className="service-details-content">
              <h4>Asthma</h4>
              <p>
                Through respiratory muscle training, physiotherapy improves a
                person’s breathing abilities and lowers the risks of asthma
                attacks.
              </p>
            </div>
            <div className="service-details-content">
              <h4>How to Book the Physiotherapy Sessions from HealBots?</h4>
              <p>
                To book the in-home physiotherapy session, make a call to
                *CUSTOMER SUPPORT NUMBER*. Provide the necessary details and get
                a physiotherapist at your home at an affordable price.
              </p>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="faq-details-content">
              <h2>Frequently Asked Questions</h2>
            </div>
            <div className="faq-details-wrapper">
              <div className="faq-details-content">
                <h4>Who is a physiotherapist?</h4>
                <p>
                  Physiotherapists help restore full function, rehabilitation,
                  mobility, and strength to people who have suffered injury or
                  disability. They also promote health and maintain overall
                  wellness.
                </p>
              </div>
              <div className="faq-details-content">
                <h4>Is physiotherapy the same as massage?</h4>
                <p>
                  There may be some massage-like techniques used in the
                  treatment, but these are entirely anatomically based.
                  Physiotherapy takes a scientific approach to solving problems.
                  Hence, it is not entirely the same as massage.
                </p>
              </div>
              <div className="faq-details-content">
                <h4>Can Physiotherapists perform surgery?</h4>
                <p>
                  The physiotherapists are not trained to perform surgery.
                  However, they may be able to provide rehabilitation services
                  alongside other health professionals.
                </p>
              </div>
              <div className="faq-details-content">
                <h4>How long will the physiotherapy session?</h4>
                <p>
                  The maximum duration for each physiotherapy session is 1 hour.
                </p>
              </div>
              <div className="faq-details-content">
                <h4>How much will each physiotherapy session cost?</h4>
                <p>
                  The cost may vary for each session and per the patient’s need.
                  However, the physiotherapy services HealBots, just like other
                  services, are affordable.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default servicePhysiotherapy;
