import React from "react";
import MaternityCare from "../../../assets/images/service/maternity-care.jpg"
const serviceMaternityCare = () => {
  return (
    <section className="service-detail-wrapper">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-12 col-md-12">
            <div className="service-detail-hero-head">
              <h1>Maternity Care</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-7 col-lg-7 col-md-7">
            <div className="service-details-content">
              <h4>What Is New Born Baby & Mother Care?</h4>
              <p>
                There are three parts to pregnancy care: prenatal, during and
                postnatal. Although prenatal care is given a lot of attention,
                postnatal care is equally vital. Both mother and child need a
                great of attention, guidance, and care during the postnatal
                period proper recovery and good health. New mothers and babies
                need special attention and care during the first 24 hours after
                birth, it is a critical time for both. Mothers who have just
                given birth can experience a variety of emotions and stress
                during this time.
              </p>
              <p>
                The experience of becoming a parent is overwhelming, especially
                in these modern times, when couples are often far from their
                families or do not have support from their families, they have
                no choice but to do it alone. With HealBots’ postnatal care
                program, new moms and dads relieve of the burden and worries.
              </p>
            </div>
            <div className="service-details-content">
              <h4>When We Can Help?</h4>
              <h5>New Mother Care</h5>
              <p>
                New mothers often experience postpartum depression after giving
                birth. Massage and emotional support can help these women to
                recover and embrace motherhood. A new mother’s recovery from a
                surgical procedure and after delivery depends heavily on
                post-operative and after-delivery care. So, HealBots is the
                place you should be looking for professional support for the
                mother.
              </p>
              <h5>New Born Baby Care</h5>
              <p>
                In the first few months of a baby’s life, a new mother finds
                herself very overwhelmed and struggling and must take special
                care of her newborn child. The caregivers at HealBots are
                specially trained to handle the needs of a new mother and her
                newborn child including new birth care, lactation support,
                massages and postnatal exercises. Let us do all the hard work
                while you embrace motherhood and our newborn baby care services.
              </p>
              <h5>In-home Care</h5>
              <p>
                Our in-home services include telephonic consultations with the
                doctor and postnatal lactation, nutrition, and nutrition advice
                during the first month following your discharge from a nursing
                home or hospital. The in-home attendants at HealBots are fully
                capable of providing support and newborn care to the babies and
                mothers as well.{" "}
              </p>
            </div>
            <div className="service-details-content">
              <h4>How Can HealBots Help?</h4>
              <p>
                Highly trained and trustworthy caregivers will provide you with
                comprehensive neonatal care and post-pregnancy as part of
                HealBots’ Mother and Baby Care program. Our primary goal is to
                offer advice on postnatal care, education, and support during
                the pre and post-pregnancy period. Having a newborn is an
                important event, and a lot of care and attention is given to
                them, but the mother must also receive post-pregnancy care.
                Providing optimum baby care along with emotional and physical
                support for the mother is the core responsibility of HealBots’
                Mother and Baby Care.
              </p>
            </div>
            <div className="service-details-content">
              <h4>Why Should You Choose Us?</h4>
              <p>
                With HealBots, you will be choosing the top-tier services for
                your loved ones in times of their needs.
              </p>
              <em>Service type and duration</em>
              <ul>
                <li>Visit 4 Hours</li>
                <li>Full Day 10 Hours</li>
                <li>Night 12 Hours</li>
                <li>Stay @Home 24 Hours</li>
              </ul>
            </div>
            <div className="service-details-content">
              <h4>Why choose HealBots Mother & Baby Care Services?</h4>
              <p>
                Designed specifically for mothers and babies post-delivery, our
                plan addresses both needs.
              </p>

              <ul>
                <li>
                  We carefully select caregivers who have hospital-based medical
                  training.
                </li>
                <li>
                  Our in-home assistants are trained to care for both mothers
                  and babies.
                </li>
                <li>
                  Our care plans are available 24 hours a day, 365 days a year.
                </li>
                <li>
                  We are a trusted partner of leading hospitals for home care.
                </li>
                <li>
                  Our top priorities are transparency, accessibility and
                  affordability.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5">
            <figure>
              <img
                src={MaternityCare}
                alt="Medical Attendant"
              />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default serviceMaternityCare;
