import React from 'react'
import Layout from '../Layout'
import HeroImage from '../../assets/images/banner-hero.png'
import HomeServiceAttendant from '../../assets/images/home-service-attendant.jpg'
import HomeServiceEquipments from '../../assets/images/home-service-equipments.jpg'
import HomeServiceIcu from '../../assets/images/home-service-icu.jpg'
import HomeServicePhysiotherapy from '../../assets/images/home-service-physiotherapy.jpg'
import HomeServiceMaternity from '../../assets/images/home-service-maternity.jpg'
import ServiceTheme1 from '../../assets/images/service-theme-1.png'
import ServiceTheme2 from '../../assets/images/service-theme-2.png'
import ServiceTheme3 from '../../assets/images/service-theme-3.png'
import ServicesItem from './services-item'

const home = () => {
    return (
        <React.Fragment>
            <section className="banner-hero">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-7 col-lg-6 col-md-6">
                            <div className="banner-hero-description">
                                <h1>Delivering World-Class Medical Home HealthCare with the comfort of your Home</h1>
                                <h3>Delivering quality care with compassion is what HealBots believes in. We strive in providing world-class medical care in the comfort of a patient’s home In Delhi NCR. With HealBots, primary healthcare becomes more accessible and affordable according to our patient’s needs. </h3>
                                <div className="bannerBtnWrapper">
                                    <span className="d-sm-none d-md-block">Need an attendant?</span>
                                    <button className="btn BtnBannerSlider" type="button" data-bs-toggle="modal" data-bs-target="#myModal">Book Now</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-md-6">
                            <div className="banner-hero-image">
                                <img className="img-fluid" src={HeroImage} alt="HealBots" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="home-service-hero">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-12 col-md-12">
                            <div className="home-service-hero-head">
                                <h4>Services at Doorstep</h4>
                                <p>Healbots offers these medical services to its patients</p>
                            </div>
                        </div>
                    </div>
                    <ServicesItem />
                </div>
            </section>
            <section className="home-callnow-section">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-8 col-lg-12 col-md-12 col-12">
                            <h6>
                                24x7 Home Healthcare Services at your Doorstep
                            </h6>
                        </div>
                        <div className="col-xl-4 col-lg-12 col-md-12 col-12 ms-auto">
                            <a href="tel:9582566200" className="btn callNowBtn" type="button">Call Now</a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="home-offered-service-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-8 col-lg-12 col-md-12">
                            <div className="home-offered-service-section-head">
                                <h4>
                                    Providing Medical Care At Home
                                </h4>
                                <p>The Team of Trained Medical Attendants at HealBots offers a Variety of healthcare services in the comfort of our patient’s homes including:</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-12 col-md-12 ">
                            <ul className="home-offered-service-list">
                                <li className="home-offered-service-list-item">
                                    <div className="description-sec">
                                        <h2>Elderly Care At Home</h2>
                                        <p>During the course of life, the human body becomes weaker. It is not uncommon for loneliness to set in, even if the body is healthy. </p>
                                        <p>The hectic lifestyles of people make it nearly impossible to spend time with their parents and grandparents in the way they deserve. Our elderly care facility is staffed with attendants, physiotherapists, and skilled nurses to ensure they remain safe and comfortable throughout their golden years.</p>
                                    </div>
                                    <div className="image-sec">
                                        <img className="img-fluid" src={HomeServiceAttendant} alt="" />
                                    </div>
                                </li>
                                <li className="home-offered-service-list-item">
                                    <div className="image-sec">
                                        <img className="img-fluid" src={HomeServiceEquipments} alt="" />
                                    </div>
                                    <div className="description-sec">
                                        <h2>Medical Equipments At Home</h2>
                                        <p>HealBots provide a wide range of medical equipment for rent or purchase according to your needs.  </p>
                                        <p>Find the equipment you are looking for from our extensive catalog and confirm your booking via email, phone call, or our website. Your required homecare medical equipment will be delivered to your doorstep.</p>
                                    </div>
                                </li>
                                <li className="home-offered-service-list-item">
                                    <div className="description-sec">
                                        <h2>ICU At Home</h2>
                                        <p>Healbots is committed to ensuring that your loved ones receive the highest quality care at home, such as ICU facilities. With our ICU care, we are able to deliver professional, hospital-like treatment at the patient’s home. </p>
                                        <p>The Healbots team is proficient in providing the best ICU care for its patients.</p>
                                    </div>
                                    <div className="image-sec">
                                        <img className="img-fluid" src={HomeServiceIcu} alt="" />
                                    </div>
                                </li>
                                <li className="home-offered-service-list-item">
                                    <div className="image-sec">
                                        <img className="img-fluid" src={HomeServicePhysiotherapy} alt="" />
                                    </div>
                                    <div className="description-sec">
                                        <h2>Physiotherapy At Home</h2>
                                        <p>Neck strain or muscle pull in the back? The general physician may recommend a daily dose of medicine in such cases; however, physiotherapy is one of the best ways to help your body parts heal; slowly, but as close to their original shapes as possible.</p>
                                        <p>HealBots can provide physiotherapy in the comfort of home to anyone, whether they are elderly, pregnant, or young.</p>
                                    </div>
                                </li>
                                <li className="home-offered-service-list-item">
                                    <div className="description-sec">
                                        <h2>Maternity and Baby Care At Home</h2>
                                        <p>The prenatal care, pregnancy care, and postnatal care aspects of pregnancy care are all interconnected. The importance of postnatal care is just as significant as prenatal care. There are many responsibilities that go along with being a parent, and if you have a little bundle of joy, you understand that there is no end to the list of things to do, thus parents do need some helping hand.</p>
                                        <p>Caregivers at HealBots are trained to meet the physical and emotional needs of both the mother and the child with the help of healBots. </p>
                                    </div>
                                    <div className="image-sec">
                                        <img className="img-fluid" src={HomeServiceMaternity} alt="" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="home-opratingcities-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-8 col-lg-12 col-md-12">
                            <div className="home-opratingcities-head">
                                <h4>We Are Operational in 30+ Areas of Delhi-NCR</h4>
                                <p>Here’s a detailed view of the footprint of HealBots' presence in Delhi-NCR. Our footprints stretched covering 30+ areas in the National Capital and surrounding region including:</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <div className="home-opratingcities-list">
                                <ul>
                                    <li>Central Delhi</li>
                                    <li>East Delhi</li>
                                    <li>New Delhi</li>
                                    <li>North Delhi</li>
                                    <li>North East Delhi</li>
                                    <li>North West Delhi</li>
                                    <li>Shahdara</li>
                                    <li>South Delhi</li>
                                    <li>South East Delhi</li>
                                    <li>South West Delhi</li>
                                    <li>West Delhi</li>
                                    <li>Bhiwani</li>
                                    <li>Charkhi Dadri</li>
                                    <li>Faridabad</li>
                                    <li>Gurgaon</li>
                                    <li>Jhajjar</li>
                                    <li>Jind</li>
                                    <li>Karnal</li>
                                    <li>Mahendragarh</li>
                                    <li>Nuh</li>
                                    <li>Palwal</li>
                                    <li>Panipat</li>
                                    <li>Rewari</li>
                                    <li>Rohtak</li>
                                    <li>Sonipat</li>
                                    <li>Alwar</li>
                                    <li>Bharatpur</li>
                                    <li>Baghpat</li>
                                    <li>Bulandshahr</li>
                                    <li>Gautam Buddh Nagar</li>
                                    <li>Ghaziabad</li>
                                    <li>Hapur</li>
                                    <li>Meerut</li>
                                    <li>Muzaffarnagar</li>
                                    <li>Shamli</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default home