import React from 'react'

const BookNowForm = ({IdProp}) => {
  return (
    <React.Fragment>
<div className="modal fade contactFormModal" id={IdProp}>
  <div className="modal-dialog">
    <div className="modal-content">

      <div className="modal-header">
        <h4 className="modal-title">Fill Up Your Enquire Form</h4>
        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
      </div>

      
      <div className="modal-body">
      <form>
  <div className="row">
    <div className="col-12">
      <input type="text" className="form-control" placeholder="Full Name" name="name" />
    </div>
    <div className="col-12">
      <input type="email" className="form-control" placeholder="Email Id" name="email" />
    </div>
    <div className="col-12">
      <input type="number" className="form-control" placeholder="Phone Number" name="phone" />
    </div>
    <div className="col-12">
      <input type="text" className="form-control" placeholder="Address" name="address" />
    </div>
    <div className="col-12">
    <textarea className="form-control" rows="3" id="comment" name="text"></textarea>
    </div>
    <div className="col-12">
    <button className="btn contactSubmitBtn" type="button">Submit Now</button>
    </div>
  </div>
   </form>
      </div>


    </div>
  </div>
</div>
</React.Fragment>
  )
}

export default BookNowForm