import React from 'react'

const footer = () => {
  return (
    <React.Fragment>
         <section className="service-process-section">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-xl-4 col-lg-12 col-md-12">
                    <div className="service-process-item">
                        <i className="fa-solid fa-truck"></i>
                        <div className="service-process-item-content">
                            <h4>Quick Shipping.</h4>
                            <p>Swift delivery of the medical equipment you need</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-12 col-md-12">
                    <div className="service-process-item">
                        <i className="fa-solid fa-headset"></i>
                        <div className="service-process-item-content">
                            <h4>24 x 7 Support.</h4>
                            <p>Having trouble? We would be happy to help</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-12 col-md-12">
                    <div className="service-process-item">
                        <i className="fa-solid fa-file-invoice"></i>
                        <div className="service-process-item-content">
                            <h4>Flexible Payments.</h4>
                            <p>We support UPI, Net Banking, or Cards for payments</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
    </section>
<footer>
    <div className="footer-area">
        <div className="container">
            <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="widget_footer_about">
                        <h3 className="footer-title">About Company</h3>
                        <div className="footer-text">
                            <p>HealBots is a pioneer in delivering world class healthcare directly to our patients' homes and aims to make primary healthcare more accessible, affordable, and responsive to patients' needs than ever before. </p>
                        </div>
                        <div className="footer-icon">
                            <a href="https://www.facebook.com/healbots" target="_blank">
                                <i className="fab fa-facebook-f"></i>
                                </a> 
                                <a href="https://www.instagram.com/healbots.official/" target="_blank">
                                <i className="fab fa-instagram"></i>
                                </a> 
                                <a href="https://www.linkedin.com/company/healbots/" target="_blank">
                                <i className="fab fa-linkedin-in"></i>
                                </a>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="widget_nav_menu">
                        <h3 className="footer-title">Useful Links</h3>
                        <div className="menu-useful-links-container">
                            <ul className="menu">
                                <li className="menu-item">
                                    <a href="/privacy-policy">Privacy Policy</a>
                                </li>
                                <li className="menu-item">
                                    <a href="/term-and-condition">Terms & Conditions</a>
                                </li>
                                <li className="menu-item">
                                    <a href="/disclaimer">Disclaimer</a>
                                </li>
                                <li className="menu-item">
                                    <a href="/faq">FAQs</a>
                                </li>
                                <li className="menu-item">
                                    <a href="sitemap.xml" target="_blank">Sitemap</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="widget_popular_services_nav_menu">
                        <h3 className="footer-title">Categories</h3>
                        <div className="menu-popular-services-container">
                            <ul className="menu">
                                <li className="menu-item">
                                    <a href="/services-critical-care">Critical Care</a>
                                </li>
                                <li className="menu-item">
                                    <a href="/services-medical-equipment">Medical Equipment</a>
                                </li>
                                <li className="menu-item">
                                    <a href="/services-attendant">Attendant</a>
                                </li>
                                <li className="menu-item">
                                    <a href="/services-physiotherapy">Physiotherapy</a>
                                </li>
                                <li className="menu-item">
                                    <a href="/services-nurse">Nurse</a>
                                </li>
                                <li className="menu-item">
                                    <a href="/services-lab-test">Lab Test</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="widget_footer_contact">
                        <h3 className="footer-title">Contact Us</h3>
                        <div className="contacts-info">
                            <div className="phone-footer"><i className="fa-solid fa-location-dot"></i>
                                <p>F-11C 1st Floor, Okhla Phase 1, Okhla Industrial Estate, New Delhi, Delhi 110020</p>
                            </div>
                            <div className="phone-footer"><i className="fa-solid fa-phone"></i>
                                <p> +91 95825 66200 </p>
                            </div>
                            <div className="phone-footer"><i className="fa-solid fa-envelope"></i>
                                <p>nitin.ranjan@healbots.in</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="footer-bottom-area">
        <div className="container">
            <div className="row footer-bottom-area-row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="copyright">
                        <p>Copyright 2022. LifeCrest Medical . All rights reserved.</p>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="footer-bottom-link">
                        <ul className="footer_right">
                            <li className="menu-item">
                            <a href="#" aria-current="page">Home</a>
                            </li>
                            <li className="menu-item">
                            <a
                            href="/services">Services</a></li>
                            <li className="menu-item">
                            <a
                            href="/about-us">About US</a></li>
                            <li className="menu-item">
                            <a
                            href="/contact-us">Contact US</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
    </React.Fragment>
  )
}

export default footer