import React from 'react'

const disclaimer = () => {
  return (
    <section className="disclaimer-hero">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="disclaimer-hero-description">
                        <h1 className="text-center">Disclaimer
                        </h1>
                    </div>
                </div>
            </div>
           
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    
                <h6 className="lastUpdatedCst text-uppercase text-left">Last Updated: 28-05-2022</h6>
</div>
</div>
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="disclaimer-detail">
                        <p className="text-left">The information provided by HealBots (“We,” “Us” or “Our”) on Https://healbots.in (the “Website”) is for general informational purposes only. All information on the Website is provided in good faith, however, We make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Website.
                        </p>
                        <p className="text-left">
                        Under no circumstance shall We have any liability to You for any loss or damage of any kind incurred as a result of the use of the Website or reliance on any information provided on the Website. Your use of the Website and Your reliance on any information on the Website is solely at Your own risk.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="disclaimer-detail">
                        <h3>Contents</h3>
                        <p className="text-left">Click below to jump to any section of this disclaimer
                        </p>
                        <ol>
                            <li>Consent</li>
                            <li>Content Disclaimer</li>
                            <li>External Links Disclaimer</li>
                            <li>Reviews</li>
                            <li>Maps Policy</li>
                            <li>Contact Us</li>
                            <li>Copyright Information</li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="disclaimer-detail">
                        <h3>Consent</h3>
                        <p className="text-left">
                        By using the Website, You hereby consent to this Disclaimer and agree to its terms.
                        </p>
                        <p className="text-left">
                        We will not be liable for any damages experienced in connection with the use of Our Website.
                        </p>
                        <p className="text-left">
                        <strong className="text-left">
                        If You do not agree with this Disclaimer, STOP now and do not access or use this Website.
                        </strong>
                        </p>
                        <p className="text-left">
                        Back to table of contents
                        </p>
                    </div>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="disclaimer-detail">
                        <h3>Content Disclaimer</h3>
                        <p className="text-left">
                        We are not responsible or liable in any manner for any content posted on Our Website or in connection with Our Website, whether posted or caused by users of the Website or by Us. Although We provide rules for user conduct and postings, We do not control and are not responsible for what the users post, transmit or share on the Website, and are not responsible for any offensive, inappropriate, obscene, unlawful or otherwise objectionable content You may encounter on the Website. We are not responsible for the conduct, whether online or offline, of any user of Our Website.

                        </p>
                        <p className="text-left">
                        Back to table of contents
                        </p>
                    </div>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="disclaimer-detail">
                        <h3>External Links Disclaimer</h3>
                        <p className="text-left">
                        The Website may contain (or You may be sent through the Website links to other sites or content belonging to or originating from third parties or links to sites and features in banners or other advertising. We do not investigate, monitor, or check such external links for accuracy, adequacy, validity, reliability, availability or completeness.
                        </p>
                        <p className="text-left">
                        We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the Website or any site or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between You and third-party providers of products or services.
                        </p>
                        <p className="text-left">
                        Back to table of contents
                        </p>
                    </div>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="disclaimer-detail">
                        <h3>Reviews</h3>
                        <p className="text-left">
                        At various times, We may provide reviews of products, services, or other resources. This may include reviews of books, services, and/or software applications. Any such reviews will represent the good-faith opinions of the author of such review. The products and services reviewed may be provided to Us for free or at a reduced price as an incentive to provide a review.                        </p>
                        <p className="text-left">
                        Regardless of any such discounts, We will provide honest reviews of these products and/or services. You recognize that You should conduct Your own due diligence and should not rely solely upon any reviews provided on this Website.
                        </p>
                        <p className="text-left">
                        We will disclose the existence of any discounts or incentives received in exchange for providing a review of a product. If You would like more information about any such discounts and incentives, send an email to nitin.ranjan@healbots.in that includes the title of the reviewed product as the subject line. We will respond via email and disclose any incentives or discounts We received in association with any such review.                        </p>
                        <p className="text-left">
                        Back to table of contents
                        </p>
                    </div>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="disclaimer-detail">
                        <h3>Maps Policy</h3>
                        <p className="text-left">
                        The map used on Our Website is published on the web by Google and sourced under an open license. The boundaries, names shown, and the designations used on this map do not imply the expression of any opinion whatsoever on Our part or its people in respect of the legal status of any country, territory, city or area, or the delimitation of any frontiers or boundaries.    
                    </p>
                        <p className="text-left">
                        Back to table of contents
                        </p>
                    </div>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="disclaimer-detail">
                        <h3>Contact Us</h3>
                        <p className="text-left">
                        If You require any more information or have any questions about Our Website's disclaimer, please feel free to contact Us by our <a href="./contact.php">contact page</a> or by email at <a href="mailto:nitin.ranjan@healbots.in">nitin.ranjan@healbots.in</a>.
                    </p>
                        <p className="text-left">
                        Back to table of contents
                        </p>
                    </div>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="disclaimer-detail">
                        <h3>Copyright Information</h3>
                        <p className="text-left">
                        COPYRIGHT 2022 HealBots. ALL RIGHTS RESERVED.
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </section>
  )
}

export default disclaimer