import React from "react";
import ServiceTheme1 from "../../assets/images/service-theme-1.png";
import ServiceTheme2 from "../../assets/images/service-theme-2.png";
import ServiceTheme3 from "../../assets/images/service-theme-3.png";

const ServicesItem = () => {
  return (
    <div className="service-card-item-wrap">
    <div className="service-card-item">
      <span className="overlay-bg"></span>
      <div className="service-card-item-overlay">
        <h6>24 x 7 Attendant</h6>
        <a href="/services-attendant">Book Now</a>
      </div>
    </div>
    <div className="service-card-item">
      <span className="overlay-bg"></span>
      <div className="service-card-item-overlay">
        <h6>Critical Care</h6>
        <a href="/services-critical-care">Book Now</a>
      </div>
    </div>
    <div className="service-card-item">
      <span className="overlay-bg"></span>
      <div className="service-card-item-overlay">
        <h6>Medical Equipment</h6>
        <a href="/services-medical-equipment">Book Now</a>
      </div>
    </div>
    <div className="service-card-item">
      <span className="overlay-bg"></span>
      <div className="service-card-item-overlay">
        <h6>Physiotherapy</h6>
        <a href="/services-physiotherapy">Book Now</a>
      </div>
    </div>
    <div className="service-card-item">
      <span className="overlay-bg"></span>
      <div className="service-card-item-overlay">
        <h6>Nurse</h6>
        <a href="/services-nurse">Book Now</a>
      </div>
    </div>
    <div className="service-card-item">
      <span className="overlay-bg"></span>
      <div className="service-card-item-overlay">
        <h6>Lab Test</h6>
        <a href="/services-lab-test">Book Now</a>
      </div>
    </div>
    <div className="service-card-item">
    <span className="overlay-bg"></span>
      <div className="service-card-item-overlay">
        <h6>X-RAY at home </h6>
        <a href="/services-xray">Book Now</a>
      </div>
    </div>
    <div className="service-card-item">
      <span className="overlay-bg"></span>
      <div className="service-card-item-overlay">
        <h6>Maternity Care</h6>
        <a href="/services-maternity-care">Book Now</a>
      </div>
    </div>
  </div>
  );
};

export default ServicesItem;
