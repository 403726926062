import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../Web/Components/home";
import AboutUs from "../Web/Components/aboutUs";
import ContactUs from "../Web/Components/contactUs";
import PrivacyPolicy from "../Web/Components/privacyPolicy";
import TermAndCondition from "../Web/Components/termAndCondition";
import Disclaimer from "../Web/Components/disclaimer";
import Faq from "../Web/Components/faq";
import Services from "../Web/Components/services";
import ServiceAttendant from "../Web/Components/Services/serviceAttendant";
import ServiceCriticalCare from "../Web/Components/Services/serviceCriticalCare";
import ServiceDiabetesCare from "../Web/Components/Services/serviceDiabetesCare";
import ServiceDietitianConsultant from "../Web/Components/Services/serviceDietitianConsultant";
import ServiceElderCare from "../Web/Components/Services/serviceElderCare";
import ServiceLabTest from "../Web/Components/Services/serviceLabTest";
import ServiceMaternityCare from "../Web/Components/Services/serviceMaternityCare";
import ServiceMedicalEquipment from "../Web/Components/Services/serviceMedicalEquipment";
import ServiceNurse from "../Web/Components/Services/serviceNurse";
import ServicePhysiotherapy from "../Web/Components/Services/servicePhysiotherapy";
import ServiceXray from "../Web/Components/Services/serviceXray";

const PageRoute = () => {
  return (
    <Routes>
      <Route>
        <Route path="/" element={<Home />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="term-and-condition" element={<TermAndCondition />} />
        <Route path="disclaimer" element={<Disclaimer />} />
        <Route path="faq" element={<Faq />} />
        <Route path="services" element={<Services />} />
        <Route path="services-attendant" element={<ServiceAttendant />} />
        <Route path="services-critical-care" element={<ServiceCriticalCare />} />
        <Route path="services-diabetes-care" element={<ServiceDiabetesCare />} />
        <Route path="services-dietitian-consultant" element={<ServiceDietitianConsultant />} />
        <Route path="services-elder-care" element={<ServiceElderCare />} />
        <Route path="services-lab-test" element={<ServiceLabTest />} />
        <Route path="services-maternity-care" element={<ServiceMaternityCare />} />
        <Route path="services-medical-equipment" element={<ServiceMedicalEquipment />} />
        <Route path="services-nurse" element={<ServiceNurse />} />
        <Route path="services-physiotherapy" element={<ServicePhysiotherapy />} />
        <Route path="services-xray" element={<ServiceXray />} />
      </Route>
    </Routes>
  )
}

export default PageRoute