import React from "react";

const serviceMedicalEquipment = () => {
  return (
    <section className="service-detail-wrapper">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-12 col-md-12">
            <div className="service-detail-hero-head">
              <h1>Medical Equipment</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <ul className="medical-equipment-list">
              <li>
                <a href="">Respiratory Care Equipment</a>
              </li>
              <li>
                <a href="">Cardiac Care Equipment</a>
              </li>
              <li>
                <a href="">Geriatric & Mobility Care</a>
              </li>
              <li>
                <a href="">Sleep Therapy Equipment</a>
              </li>
              <li>
                <a href="">Orthopedic Equipment</a>
              </li>
              <li>
                <a href="">Mask & Accessories</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default serviceMedicalEquipment;
